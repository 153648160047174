// Generated by Framer (56d1180)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wcwV25rSW", "uBHVshNtG", "jSZPxtcgA"];

const serializationHash = "framer-rvnvZ"

const variantClassNames = {jSZPxtcgA: "framer-v-8da1k3", uBHVshNtG: "framer-v-1fut7ah", wcwV25rSW: "framer-v-23lan1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Line IN": "uBHVshNtG", "Line OUT": "jSZPxtcgA", Default: "wcwV25rSW"}

const getProps = ({height, id, label, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "wcwV25rSW", X4kGlDs4u: label ?? props.X4kGlDs4u ?? "UNDERLINE BUTTON"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;label?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, X4kGlDs4u, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wcwV25rSW", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterlz17i9 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
setVariant("uBHVshNtG")
})

const onMouseLeave1vy2mpv = activeVariantCallback(async (...args) => {
setGestureState({isHovered: false})
setVariant("jSZPxtcgA")
})

const onAppearbjfmit = activeVariantCallback(async (...args) => {
await delay(() => setVariant("wcwV25rSW"), 350)
})

useOnVariantChange(baseVariant, {jSZPxtcgA: onAppearbjfmit})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({jSZPxtcgA: {value: transition2}, uBHVshNtG: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-23lan1", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"wcwV25rSW"} onMouseEnter={onMouseEnterlz17i9} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({jSZPxtcgA: {"data-framer-name": "Line OUT", onMouseEnter: undefined}, uBHVshNtG: {"data-framer-name": "Line IN", onMouseEnter: undefined, onMouseLeave: onMouseLeave1vy2mpv}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.03em"}}>UNDERLINE BUTTON</motion.p></React.Fragment>} className={"framer-1bzuv4l"} data-framer-name={"Label"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"kL0pIFrId"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={X4kGlDs4u} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-snivg6"} data-framer-name={"Underline Wrap"} layoutDependency={layoutDependency} layoutId={"RKC082jen"}><motion.div className={"framer-1qatlvk"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"ULK9wvMMY"} style={{backgroundColor: "rgb(0, 0, 0)"}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rvnvZ.framer-1b4yjz1, .framer-rvnvZ .framer-1b4yjz1 { display: block; }", ".framer-rvnvZ.framer-23lan1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 2px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-rvnvZ .framer-1bzuv4l { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-rvnvZ .framer-snivg6 { align-self: stretch; flex: none; height: 2px; overflow: hidden; position: relative; width: auto; }", ".framer-rvnvZ .framer-1qatlvk { bottom: 0px; flex: none; left: -10px; overflow: hidden; position: absolute; top: 0px; width: 1%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rvnvZ.framer-23lan1 { gap: 0px; } .framer-rvnvZ.framer-23lan1 > * { margin: 0px; margin-bottom: calc(2px / 2); margin-top: calc(2px / 2); } .framer-rvnvZ.framer-23lan1 > :first-child { margin-top: 0px; } .framer-rvnvZ.framer-23lan1 > :last-child { margin-bottom: 0px; } }", ".framer-rvnvZ.framer-v-1fut7ah .framer-1qatlvk { left: 0px; right: 0px; width: unset; }", ".framer-rvnvZ.framer-v-8da1k3 .framer-1qatlvk { left: unset; right: -10px; width: 1%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 33
 * @framerIntrinsicWidth 232
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"uBHVshNtG":{"layout":["auto","auto"]},"jSZPxtcgA":{"layout":["auto","auto"]}}}
 * @framerVariables {"X4kGlDs4u":"label"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSUYaSLYys: React.ComponentType<Props> = withCSS(Component, css, "framer-rvnvZ") as typeof Component;
export default FramerSUYaSLYys;

FramerSUYaSLYys.displayName = "Underline Button";

FramerSUYaSLYys.defaultProps = {height: 33, width: 232};

addPropertyControls(FramerSUYaSLYys, {variant: {options: ["wcwV25rSW", "uBHVshNtG", "jSZPxtcgA"], optionTitles: ["Default", "Line IN", "Line OUT"], title: "Variant", type: ControlType.Enum}, X4kGlDs4u: {defaultValue: "UNDERLINE BUTTON", displayTextArea: false, title: "Label", type: ControlType.String}} as any)

addFonts(FramerSUYaSLYys, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})